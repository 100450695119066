.closeBtn {
  position: fixed;
  top: 8px;
  right: 10px;
}

/* Components */
@media only screen and (min-width: 500px) {
  .formContent {
    position: block;
    display: block;
    flex-direction: column;
    padding: 1em;
    text-align: justify;
    box-sizing: "border-box";
  }
}

@media only screen and (max-width: 500px) {
  .formContent {
    display: block;
    flex-direction: column;
    margin: 1em;
    box-sizing: "border-box";
  }
}

@media only screen and (min-width: 500px) {
  .formContentInput {
    display: flex;
    flex-direction: column;
    padding: 1rem 0rem;
  }
}

@media only screen and (max-width: 500px) {
  .formContentInput {
    display: flex;
    flex-direction: column;
    padding: 1rem 0rem;
  }
}

.btnContainerStart {
  display: flex;
  padding: 1rem 1rem;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.btnContainer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  padding-top: 1rem;
  margin-left: auto;
  margin-right: 0;
  gap: 1rem;
}

.formContent .formContentInput {
  gap: 10px;
}

.messageBox {
  background-color: white;
  padding: 1rem 1rem;
  border-radius: 8px;
}

.messageBox p {
  line-height: 1.5;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.formContentHeader {
  margin-top: 0rem;
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
}
