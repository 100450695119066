* {
  margin: 0;
  padding: 0;
  font-family: "Open Sans";
}

html {
  background-color: #EEEEEE;
}

.header {
  background-color: rgb(214, 214, 214);
  padding: 10px 5px;
}

.header img {
  float: left;
  width: 50px;
  height: 50px;
}

.header h1 {
  position: relative;
  top: 3px;
  left: 0px;
}

.modalBtn {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  padding: 12px 24px;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}